// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

import { Router, Route, Private } from '@redwoodjs/router'

const Routes = () => {
  return (
    <Router>
      <Route path="/about" page={AboutPage} name="about" />
      <Route path="/" page={HomePage} name="home" />
      <Private unauthenticated="home">
        <Route path="/profiles/new" page={NewProfilePage} name="newProfile" />
        <Route
          path="/profiles/{id:Int}/edit"
          page={EditProfilePage}
          name="editProfile"
        />
        <Route path="/profiles/{id:Int}" page={ProfilePage} name="profile" />
        <Route path="/profiles" page={ProfilesPage} name="profiles" />
        <Route path="/addresses/new" page={NewAddressPage} name="newAddress" />
        <Route
          path="/addresses/{id:Int}/edit"
          page={EditAddressPage}
          name="editAddress"
        />
        <Route path="/addresses/{id:Int}" page={AddressPage} name="address" />
        <Route path="/addresses" page={AddressesPage} name="addresses" />
        <Route
          path="/inspections/new"
          page={NewInspectionPage}
          name="newInspection"
        />
        <Route
          path="/inspections/{id:Int}/edit"
          page={EditInspectionPage}
          name="editInspection"
        />
        <Route
          path="/inspections/{id:Int}"
          page={InspectionPage}
          name="inspection"
        />
        <Route path="/inspections" page={InspectionsPage} name="inspections" />
      </Private>

      <Route notfound page={NotFoundPage} />
    </Router>
  )
}

export default Routes
